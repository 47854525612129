<template>
	<v-app>
		<v-data-table
			:headers="headers"
			:items="cameras"
			:hide-default-footer="false"
			:items-per-page="50"
			sort-by="name"
			@click:row="handleClick"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>カメラ一覧</v-toolbar-title>
					<div style="margin: 10px">
						<span>メール送信先: </span><br />
						<span style="color: #ff0000">{{
							config.cameraEmailAddress
						}}</span>
					</div>
				</v-toolbar>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon small class="edit" @click="editItem(item)">
					mdi-pencil
				</v-icon>
			</template>
		</v-data-table>
		<v-footer>
			©2021 <a href="https://www.vegetalia.co.jp/">vegetalia</a>, inc.
		</v-footer>
	</v-app>
</template>

<script>
import axios from "axios";

export default {
	components: {},
	data: () => ({
		isLoading: false,
		headers: [
			{ text: "名称", value: "name" },
			{ text: "IMEI", align: "start", value: "id" },
			{ text: "電話番号", value: "phoneNumber" },
			{ text: "地点", value: "placeName" },
			{ text: "最終画像取得日時", value: "createdAt" },
			{ text: "", value: "actions", sortable: false },
		],
		editedIndex: -1,
		cameras: [],
	}),
	created() {
		axios.defaults.headers.common["Authorization"] =
			"Bearer " + this.$store.state.auth.data.token;
		this.initialize();
	},
	methods: {
		initialize() {
			this.showLoading();
			axios
				.get(
					"https://" +
						this.config.defaultOriginString +
						"/api/admin-cameras"
				)
				.then((res) => {
					this.hideLoading();
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						this.cameras = res.data.cameras;
						this.cameras.map(function (value, index, array) {
							array[index].id = value.imei;
						});
						console.log("Success: " + JSON.stringify(res.data));
					} else {
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
				});
		},
		editItem(item) {
			this.$router
				.push({ name: "AdminEditCamera", params: { id: item.imei } })
				.catch(() => {});
		},
		showLoading: function () {
			this.$emit("loadShowHide", true);
		},
		hideLoading: function () {
			this.$emit("loadShowHide", false);
		},
		handleClick(value, e) {
			// TODO: モバイルで開くとき、すべてのテーブルが同時にひらいてしまう。他ページと書き方一緒なのに、ここだけ変
			e.select(!e.isSelected);
		},
	},
};
</script>

